<template>
    <div>
        <st-data-table
            :items="accounts"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="editAccount"
            responsive="sm"
            hover
        >
        </st-data-table>

        <edit-account-modal ref='edit-account-modal' :selectedAccount="selectedAccount"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AccountModel } from '@/modules/accounts/account-model';
import EditAccountModal from './EditAccountModal.vue';

const { fields } = AccountModel;

export default {
    name: 'AccountsTabel',
    components: {
        EditAccountModal,
    },
    data() {
        return {
            presenter: AccountModel.presenter,
            fields: [
                fields.user_name.extendField({ bold: true }),
                fields.email,
                fields.role.extendField({ bold: true }),
                fields.status.extendField({ labelType: true }),
                fields.created_by_name_string,
                {
                    key:'Actions',
                    label: this.$t('ACCOUNTS.LIST.ACTIONS'),
                },
            ],
        }
    },
    methods: {
        ...mapActions({
            selectAccount: 'accounts/selectAccount',
        }),
        async editAccount(item) {
            await this.selectAccount(item.item);
            this.$refs['edit-account-modal'].show();
        },
    },
    computed: {
        ...mapGetters({
            accounts: 'accounts/accounts',
            selectedAccount: 'accounts/selectedAccount',
            permissions: 'accounts/permissions',
        }),
        actions() {
            return [
                {
                    name: 'edit',
                    icon: 'edit',
                    tooltipText: this.$t('ACCOUNTS.LIST.EDIT'),
                    customIcon: false,
                    type: 'primary',
                    disabled: !this.permissions.hasPermissionToEdit,
                },
            ]
        }
    },
}
</script>
